import 'lib/browser/browser-v1.0.0';
import 'lib/phone-mask/phone-mask-v2.0.0';

$(document).ready(function () {

	// People search form
	$('.search-state option[value="ALL"]').remove();

	$('.people-search').on('keyup', function (){
		if ($(this).find('.search-first-name').val().length !== 0 && $(this).find('.search-last-name').val().length !== 0) {
			$(this).find('.people-error-message').hide();
		}
	});

	$(document).ready(function() {
		// Attach onchange event listeners to input fields
		$('.search-city, .search-state, .search-first-name, .search-last-name').on('input', function() {
			let searchCity = $('.search-city').val().trim().toLowerCase();
			let searchState = $('.search-state').val().trim();
			let searchFirst = $('.search-first-name').val().trim().toLowerCase();
			let searchLast = $('.search-last-name').val().trim().toLowerCase();
	
			if (searchState && searchState.toLowerCase() === 'district of columbia') {
				searchState = '';
			} else {
				searchState = searchState.toLowerCase().replace(/\s/g, '-');
			}
	
			if (searchCity.length !== 0 && searchState.length === 0) {
				$('.city-state-error-message').show();
				$('.search-state').css('background-color', '#f5ffad');
			} else {
				$('.city-state-error-message').hide();
				$('.search-state').css('background-color', '');
			}
			
			if (searchFirst.length !== 0 && searchLast.length === 0) {
				$('.people-error-message').show();
				$('.search-last-name').css('background-color', '#f5ffad');
			} else {
				$('.people-error-message').hide();
				$('.search-last-name').css('background-color', '');
			}
		});
	});
	
	$(document).on('submit', 'form.people-search', function (e) {
		e.preventDefault();
		let searchFirst = $(this).find('.search-first-name').val().trim().toLowerCase();
		let searchLast = $(this).find('.search-last-name').val().trim().toLowerCase();
		let searchCity = $(this).find('.search-city').val() ? $(this).find('.search-city').val().toLowerCase() : '';
		let searchState = $(this).find('.search-state').val().trim();
	
		if (searchState && searchState.toLowerCase() === 'district of columbia') {
			searchState = '';
		} else {
			searchState = searchState.toLowerCase().replace(/\s/g, '-');
		}
	
		let cityPlus = searchCity.split(' ').join('-');
		let searchCityState = searchState + '/' + cityPlus;
	
		if (
			searchFirst.length !== 0 && searchLast.length !== 0 && (searchCity.length !== 0 && searchState.length === 0) ) {
			//Can't submit until visitor selects a state.
		} else if (searchFirst.length !== 0 && searchLast.length !== 0 && (searchCity.length === 0 && searchState.length !== 0) ) {
			window.location = '/people/' + searchFirst + '-' + searchLast + '/' + searchState + '/';
		} else if (searchFirst.length !== 0 && searchLast.length !== 0 && (searchCity.length === 0 || searchState.length === 0) ) {
			window.location = '/people/' + searchFirst + '-' + searchLast + '/';
		} else if (searchFirst.length !== 0 && searchLast.length !== 0 && (searchCity.length !== 0 || searchState.length !== 0) ) {
			window.location = '/people/' + searchFirst + '-' + searchLast + '/' + searchCityState + '/';
		}
		else {
			$(this).find('.people-error-message').show();
		}
	});

	// Phone search form
	$(document).on('submit', 'form.phone-search', function (e){
		e.preventDefault();
		let phone_number = $('#phone').val(),
			phone_regex = /[0-9]{10}/;

		phone_number = phone_number.replace(/\(|\)|-| /g, '');

		if (phone_regex.test(phone_number)){
			$('.phone-error-message').removeClass('active');
			window.location = '/phone/' + phone_number;
			return true;
		} else {
			$('.phone-error-message').addClass('active');
			return false;
		}
	});

	// Phone Number Masking
	$("#phone").mask("(999) 999-9999");

	$("#phone").on("blur", function () {
		let last = $(this).val().substr( $(this).val().indexOf("-") + 1 );

		if( last.length == 5 ) {
			let move = $(this).val().substr( $(this).val().indexOf("-") + 1, 1 );

			let lastfour = last.substr(1,4);

			let first = $(this).val().substr( 0, 9 );

			$(this).val( first + move + '-' + lastfour );
		}
	});

	//mobile search update form
	//if(window.isMobile) {
	if ($('.mobile-group').length) {
		$("#tabs").hide();

		//close button
		$( ".closebtn" ).click(function () {
			$("#tabs").hide();
			$("#searchMobile").show();
			$(".sub-container").removeClass('opacity-lt');
			$(".containerbox").removeClass('bg-dark');

			//enable scrolling
			$('html, body').css({
				overflow: 'auto',
				height: 'auto'
			});
		});

		//mobile form 
		$( "#searchMobile" ).click(function () {
			$("#tabs").show();
			$("#searchMobile").hide();
			$(".sub-container").addClass('opacity-lt');
			$(".containerbox").addClass('bg-dark');

			//disable scrolling
			$('html, body').css({
				overflow: 'hidden',
				height: '100%'
			});
		});	
	}
});
