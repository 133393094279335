import 'lib/modal-v2.3.0.js';
import cookies from 'lib/browser-cookies.js';
import 'js/_partials/hero.js';
import '_partials/simple_search.js';

// --------------------------------------------------
// MODAL
$(() => {
    // Set Up the Modal
	window.warningModal = $.modal({
		closable: false,
		content: '#warning-modal',
		ready: function (e) {
			$('body').css('overflow', 'auto');

			let verify = $(".verify");
			verify.click(function (){
				$('#checkbox').addClass('checked-box');
				cookies.set('warningModalPopped', 'true', 1);
				cookies.set('fcra_terms_accepted', `${new Date().toISOString()}`, {expires: 1});
				setTimeout(function (){
					window.warningModal.close();
				}, 2000);
			});
		}
	});

	if (!cookies.get('warningModalPopped')) {
		window.warningModal.open();
	}
    
});
// END MODAL
// --------------------------------------------------
